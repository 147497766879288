.FrmModifierCellier {
	text-align: center;

	.form-ajout--container {
		max-width: 400px;
		margin: 0 auto;

		span {
			color: #cc4240;
		}

		label {
			font-size: 15px;
			font-weight: 300;
		}
		
		input {
			margin-top: 20px;
			margin-bottom: 20px;
			padding: 5px;
			width: 100%;
			font-size: 15px;
		}

		button {
			background-color: #152440;
			border-radius: 4px;
			border: none;
			color: #f3f5eb;
			font-family: Alata, sans-serif;
			font-size: 12px;
			margin-top: 20px;
			padding: 10px 25px 10px 25px;
			text-transform: uppercase;

			&:hover {
				background-color: #f1ab50;
				color: #152440;
				cursor: pointer;
			}
		}
	}
}