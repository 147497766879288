.FrmAjoutBouteille {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: auto;
	max-width: 900px;

	h1 {
		text-align: center;
	}

	.formInputNom {
		padding-top: 15px;
	}

	.autocomplete {
		padding-bottom: 8px;
	}

	.FrmAjoutNouvelle {
		margin: 20px;
		label {
			display: block;
			font-size: 14px;
			margin-top: 10px;
		}

		.img--wrap {
			background-color: #d3d7dd;
			height: 180px;
			img {
				padding: 5px 0 5px 0;
				mix-blend-mode: multiply;
				object-fit: contain;
				height: 100%;
				width: 100%;
			}
		}

		.btn--ajouter {
			background-color: #101e33;
			border: none;
			border-radius: 4px;
			color: #f3f5eb;
			font-family: Alata;
			text-transform: uppercase;
			padding: 10px 30px;

			&:hover {
				background-color: #f1ab50;
				color: #101e33;
				cursor: pointer;
			}
		}
	} 

	.nonSelect {
		opacity:0.7;
		cursor: none;
	}

	.bandeRouge {
		background-color: #cc4240;
		padding: 10px;
		width: auto;
	}

	.active {
		color: #cc4240;
	}
	
	.Grid p {
		font-size: 12px;
		margin-top: 0;
	}
}

.MuiAutocomplete-popperDisablePortal {
	z-index: 10;
	
	* {
		font-family: Raleway;
		font-size: 14px;
	}
}