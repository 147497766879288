.DateSelecteurAnnee {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    text-align: left;

    label {
        margin-right: 10px;
    }

    .DateInput {
        flex: 1;
    }
}

.hidden{
    display: none;
}