.Aide {
	h1 {
		color: #152440;
		padding-bottom: 50px;
	}

	.Appli--container {
		background-image: linear-gradient(
			to bottom,
			transparent 65%,
			#d3d7dd78 50%
		);
		padding-bottom:0;
		padding-top:0;

		.Aide--fonctionnement-container {
			h2 {
				font-size: 14px;
				font-weight: 500;
				padding-bottom: 10px;
			}

			p {
				font-size: 12px;
			}

			.Aide--fonctionnement-grid {
				display: grid;
				gap: 20px;
				grid-template-columns: repeat(1, 1fr);

				.Aide--fonctionnement-grid-tile {
					border: 0.5px solid #d3d7dd;
					border-radius: 4px 4px 0 0;
					box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
					padding: 20px;
					 div  {
						display: flex;
						justify-content: space-between;
						position: relative;
					 }
					&:hover {
						background-color: #d3d7dd37;
					}

					img {
						border: 1px solid #d3d7dd;
						background-color: #d3d7dd37;
					}

					@media only screen and (min-width: 1200px) { 
						&:last-of-type {
							grid-column-start: 1;
							grid-column-end: 3;
							max-width: none;
						}
					}
				}

				@media only screen and (min-width: 1200px) { 
					grid-template-columns: repeat(2, 1fr);
				}
			}
			.Aide--questions-container {
				h2 {
					margin-bottom: 15px;
					margin-top: 25px;
				}
			}

			@media only screen and (min-width: 768px) {
				padding-right: 50px;
				padding-bottom: 100px;
			}
		}

		.Aide--second-section {
			padding:0 25px 25px 25px;
			margin-top: 100px;
			
			.Aide--accordion {
				* {
					font-family: raleway;
					font-size: 14px;
				}

				.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
					background-color: #f3f5eb;
					border: 0.5px solid #d3d7dd;
					box-shadow: none;

				}
			}

			.Aide--contact-container {
				margin-bottom: 50px;
				margin-top:50px;

				p {
					padding-bottom: 10px;
				}
				.Aide--contact {
					color: #152440;
					font-size: 12px;
					text-underline-offset: 5px;
					text-decoration: underline 1px;
					&:hover {
						color: #cc4240;
					}
				 }
			}
				
			@media only screen and (min-width: 900px) {
				background-color: #d3d7dd78;
				max-width: 500px;
				min-width: 500px;
			}
		}
		
		@media only screen and (min-width: 900px) {
			background-image: none;
			display: flex;
			justify-content: space-between;
		}
	}
}