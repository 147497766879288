.BouteilleInventaire {
  border: 0.5px solid #d3d7dd;
  border-radius: 4px 4px 0 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

  .bouteille--gestion {
    background-color: #d3d7dd78;
    height: 180px;
    position: relative;

    img {
      padding: 5px 0 5px 0;
      object-fit: contain;
      height: 100%;
      width: 100%;
      max-height: 250px;
      mix-blend-mode: multiply;
    }
  }

  .bouteille--info-container {
    padding: 10px;

    .bouteille--nom {
      min-height: 70px;
      font-weight: 600;

      small {
        font-size: 11px;
        font-weight: 300;
      }

      @media only screen and (min-width: 350px) {
        min-height: 60px;
      }

      @media only screen and (min-width: 400px) {
        min-height: 50px;
      }
    }
    .bouteille--info {
      button {
        font-size: 10px;
      }
    }
    .detail--container {
      max-height: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      .bouteille--nom--wrapper {
        max-height: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      align-items: baseline;
      display: flex;
      justify-content: space-between;
    }
  }

  .quantite--container {
    left: 5px;
    position: absolute;
    top: 5px;

    .quantite {
      background-color: #f1ab50;
      border-radius: 140px;
      color: #152440;
      font-family: Raleway;
      font-size: 12px;
      font-weight: 600;
      margin: 5px;
      min-width: 35px;
      padding: 8px 8px;
      text-align: center;
    }
  }

  .prix--container {
    .prix {
      color: #152440;
      font-family: Raleway;
      font-size: 11px;
      font-weight: 500;
      min-width: 35px;
    }
  }

  .snackbar-wrapper {
    position: absolute;
    right: 0;
    top: 0;
  }

  &:hover {
    background-color: #d3d7dd40;
  }
}

.Bouteille--solo {
  max-width: 325px;
}
