.Profil {
	border: 1px solid #d3d7dd;
	font-family: Raleway light (300);
	padding: 40px;
	width: 100%;
	
	.img {
		img {
			height: 200px;
			object-fit: contain;
			width: 200px;
		}
	}

	.snackbar-wrapper {
		position: absolute;
		right:0;
		top: 0;
	}
	
	.description-username {
		margin-top:-20px;
	}

	.modifier {
		background-color: transparent;
		border: none;
		text-decoration: underline 1px; 
		text-underline-offset: 5px;
		
		&:hover {
			color:#f1ab50;
			cursor: pointer;
		}
	}
  
 	.infos-modification {
		display: flex;
		font-size: 15px;
		justify-content: space-between;
		margin-bottom: -1rem;
		width: 100%;
	}
}

.Profil--container {
	max-width: 800px;
}

.boutonSupprimer {
	background-color:transparent;
	border: none;
	display: flex;
	font-family: Raleway light (300);
	font-size: 15px;
	justify-content: flex-start;
	margin-bottom: 2rem;
	margin-left: -0.1rem;
	margin-top: 10px;
	text-decoration: underline 1px;
	text-underline-offset: 5px;
	&:hover {
		color:#cc4240;
		cursor: pointer;
	}
}

a:link {
	text-decoration: none;	
}

.amplify-input:focus {
    border-color: #f1ab50;
    box-shadow: none;
}

.amplify-input:disabled {
	background-color:#d3d7dd78;
	border-color: #f1ab50;
	cursor: var(--amplify-components-fieldcontrol-disabled-cursor);
}

.infos-profil {
	align-items: center;
	display: flex;
	flex-direction: row;
	margin-bottom: 25px;
	margin-top: 50px;

	h1 {
		padding-left: 25px;
		margin-top: -25px;
		text-transform: lowercase;
	}

	.icone-profil {
		@media only screen and (min-width: 768px) {
			margin-top: 0px;
			padding: 1.6rem;
			width: 8rem;    
		}

		background-color: #d3d7dd;
		border: 1px solid #f1ab50;
		border-radius: 50%;
		box-shadow: none;
		padding: 1.4rem;
		width: 7rem;
	}

	p {
		color: #152440;
		font-family: Raleway light (300);
		font-size:1rem;
		font-weight:600;
		margin-left: 1rem;
	}
}