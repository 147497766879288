.frmPassword {
    .PasswordField {
        margin-top: 10px;
    }

	label,
	p {
		font-family: raleway;
		font-size: 12px;
	}

	.amplify-field__error-message {
		color: #cc4240;
	}
	
	.amplify-input--error {
		border-color: #cc4240;
		&:focus {
			border-color: #f1ab50;
		}
	}
}

input {
    text-align: left;
    &:focus {
        border-color: #f1ab50;
        box-shadow: none;
    }
}