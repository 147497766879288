@media only screen and (max-width: 768px) {
  html::-webkit-scrollbar {
    display: none;
  }
}

body {
  background-color: #f3f5eb92;
  font-family: raleway;
  font-size: 0.7rem;
  margin: auto;
  padding-bottom: 50px;
}

button {
  transition: background-color 0.2s ease-in, color 0.2s ease-in;
}

h1 {
  font-family: Alata;
  font-size: 18px;
  margin: 0;
  padding-bottom: 25px;
  padding-top: 50px;
  text-transform: uppercase;

  @media only screen and (min-width: 768px) {
    font-size: 18px;
    padding-top: 0;
  }
}

h2 {
  font-family: Raleway;
  font-weight: 400;
  font-size: 15px;
  margin: 0;
  padding-bottom: 25px;
}

img {
  max-width: 100%;
}

hr {
  background-image: linear-gradient(#152440, rgba(0, 0, 0, 0.75));
  border: 0;
  height: 0.5px;
}

.action {
  background-color: #152440;
  border: 1px solid #152440;
  border-radius: 4px;
  color: #f3f5eb;
  font-family: Alata;
  font-size: 12px;
  padding: 11px 20px;
  text-transform: uppercase;
  text-decoration: "none";

  &:hover {
    background-color: #f1ab50;
    border: 1px solid #f1ab50;
    color: #152440;
    cursor: pointer;
  }
}

.css-8vnh0h-MuiPaper-root-MuiAlert-root {
  background-color: #152440 !important;
  border: 1px solid #f1ab50;
  .MuiAlert-message {
    font-family: raleway;
  }

  button {
    &:hover {
      background-color: #f1ab50;
      color: #152440;
    }
  }
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #f1ab50 !important;
  border-width: 1px !important;
}

.Hidden {
  display: none;
}
