.frmUsername {
    p {
		font-family: raleway;
		font-size: 12px;
        text-align: center;
    }

	.css-24rejj-MuiInputBase-input-MuiOutlinedInput-input {
		padding: 10px;
	}
}