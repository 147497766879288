.NavDesktop {
	display: none;
	position: fixed;
	width: 100%;
	z-index: 1;

	@media only screen and (min-width: 768px) {
		background-color: #f3f5eb;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
		display: flex;
		justify-content: space-between;
		padding: 10px 5vw 10px 5vw;
	
		.logo {
			height: 50px;
			width: 120px;
		}

		.iconeAddBottle {
			border: 1px solid #f1ab50;
			border-radius: 120px;
			height:50px;
			margin-right: 25px;
			&:hover {
				border: 1px solid #d3d7dd;
				scale: 1.02;
			}
		}
	
		.actionMenu {
			display: flex;
		}
	
		.navDesktop--container-profil {
			cursor: pointer;
			display: flex;
			p {
				padding-top: 10px;
			}
			img {
				margin-right: 15px;
			}
		}
	}

	@media only screen and (min-width: 1200px) {
		padding: 10px 12vw 10px 12vw;
	}

	@media only screen and (min-width: 1800px) {
		padding: 10px 20vw 10px 20vw;
	}
}

.MuiList-root {
	// display: none;
	span {
		font-family: Raleway;
		font-size: 14px;
	}
	@media only screen and (min-width: 768px) {
		display: block;
		a span {
			padding-top:5px;
		}
		a:hover {
			background-color: transparent;
			color: #cc4240;
		}
	}
}