.FrmBouteilleInput {
	align-items: center;
	display: flex;
	flex-wrap: nowrap;
	margin: 4px 0;
	text-align: left;

	label {
		margin-right: 10px;
		width: 100px;
	}

	.DateInput {
		flex: 1;
	}

	&:focus {
		border:none;
	}
}

.hidden {
	display: none;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
	font-family: raleway;
}